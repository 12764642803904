/* Navbar.css */

.navbar {
  width: 100vw; /* Ensures full viewport width */
  padding: 1rem 2rem;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
}

.logo {
  z-index: 15; /* Ensures the logo stays on top */
}

.logo img {
  height: 40px;
  transition: height 0.3s ease;
}

.burger {
  display: none;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 2px;
  height: 30px;
  margin-top: 8px;
  background: transparent;
  z-index: 15; /* Ensures burger icon stays on top */
}

.burger span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #ddd;
}

/* Show burger menu on small screens */
@media (max-width: 768px) {
  .burger {
    display: block;
  }

  .nav-links {
    width: 100vw; /* Ensures full viewport width for mobile menu */
    position: fixed;
    top: -1em;
    left: 0;
    padding: 0;
    height: 100vh;
    background-color: rgba(44, 44, 44, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    list-style: none;
    opacity: 0;
    transform: translateX(100%);
  }

  .nav-links li {
    opacity: 0; /* Initial opacity for staggered animation */
    padding: 1rem;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  }

  .nav-links li:last-child {
    border-bottom: none;
  }
  .nav-links a {
    font-size: 1.5rem;
  }
}
.navButton {
  padding: 0;
  margin-top: -50px;
  margin-left: 16px;
  transform: translateY(18px);
}

.mobileshow {
  display: none;
}

@media (max-width: 768px) {
  .hideevenonmobile {
    display: none;
  }
  .mobile-wrapper-nav {
    display: flex;
    flex-flow: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
  .mobileshow {
    display: inline-block;
  }
  .mobileshow a {
    display: inline-block;
    transform: translateY(4px);
    padding: 12px;
  }
  .navButton {
    padding: 0;
    margin-top: 0px;
    margin-left: 0px;
    transform: translateY(0px);
  }
}
