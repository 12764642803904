.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-size: 12vw;
  pointer-events: none;
  flex-flow: column;
}
.preloader .logo {
  height: 100vh;
  display: flex;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.preloader .logo img {
  height: 64px;
  display: inline-block;
}
.pre-number {
  position: absolute;
  right: 128px;
  bottom: 24px;
}
