.sliding-gallery-wrapper {
  width: 100vw;
  position: relative;
  height: auto; 
  overflow-x: hidden;
}

.sliding-gallery {
  overflow: hidden;
  padding: 64px 0;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next row */
  gap: 24px; /* Remove gaps */
  justify-content: center; /* Space between columns */
  height: auto; 
  /* pointer-events: none; */
  /* background: url('./img/bg-sec03.png'); */
  background-size: cover;
}
.sliding-gallery .slick-track {
  align-items: center;
}
.sliding-gallery .slick-track img {
  object-fit: cover;
  object-position: center;
}

.sliding-gallery .swiper-wrapper {
  transition-timing-function: linear !important;
  align-items: center;
}
.sliding-gallery img {
  object-fit: cover;
  object-position: center;
  border-radius: 24px;
  /* width: 240px; */
  max-height: 640px;
  height: auto;
  margin: 0 12px;
  display: block;
}
.sliding-gallery .swiper {
  padding: 24px !important;
}

.sliding-gallery .swiper-slide {
  margin: 0 24px;
}

.image {
  width: calc(33.33% - 50px); /* Divide into 3 columns without gaps */
  height: auto;
  display: block;
  max-height: 400px;
  object-fit: cover;
}

.image.loaded {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .image {
    min-height: 300px;
    width: calc(50% - 50px); /* Divide into 3 columns without gaps */
  }
}
