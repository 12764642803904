.image-with-hotspots {
  position: relative;
}

.image-with-hotspots img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image-with-hotspots img {
    height: 65vh;
    object-fit: cover;
  }
}

.hotspot {
  position: absolute;
  width: 30px; /* Outer ring size */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.hotspot .inner-circle {
  width: 14px; /* Inner circle size */
  height: 14px;
  background-color: #e6ff24; /* Inner circle color */
  border-radius: 50%;
  box-shadow: 0 0 0 6px #e6ff24; /* Outer ring effect */
}

.tooltip {
  position: absolute;
  transform: translate(-50%, -140%);
}

.tooltip-content {
  background-color: #e6ff24;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
