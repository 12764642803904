.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9999;
  font-size: 12vw;
  pointer-events: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
}

.preloader .logo {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  position: absolute;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
}

.preloader .logo img {
  height: 64px;
  display: inline-block;
}

.pre-number {
  position: absolute;
  right: 128px;
  bottom: 24px;
}
